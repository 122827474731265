import React, { useState } from 'react';
import './Footer.css';

function Footer() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleFooter = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className={`footer-toggle ${isOpen ? 'open' : ''}`} onClick={toggleFooter}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <footer className={`footer ${isOpen ? 'open' : ''}`}>
                <div className="footer-content">
                    <div className="footer-section">
                        <h4>Heartverse</h4>
                        <p>177-87-00510</p>
                        <p>대표: 도민석</p>
                    </div>
                    <div className="footer-section">
                        <h4>Contact</h4>
                        <p>Email: herat@heartverse.xyz</p>
                    </div>
                    <div className="footer-section">
                        <h4>Address</h4>
                        <p>신대로 145 2-07</p>
                        <p>제주특별자치도 제주시</p>
                    </div>
                    <div className="footer-section">
                        <h4>Rights</h4>
                        <p>&copy; 2024 HeartVerse. All rights reserved.</p>
                        <p> Music by <a href="https://pixabay.com/users/soulprodmusic-30064790/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=228100">Oleg Fedak </a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=228100">Pixabay</a></p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;